import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Seo from "src/components/SEO"
import { PrimaryTitle } from "src/components/Typography"
import BaseContainer from "src/components/Base/BaseContainer"

const TermsOfUsePage = () => {
  return (
    <>
      <Seo title="Content License Agreement" />
      <PageHeader>
        <PrimaryTitle>Content license agreement</PrimaryTitle>
      </PageHeader>
      <Container narrow>
        <List>
          <Item>
            <strong>Please read these Terms</strong> carefully before using and
            downloading materials on this website. By using This Website
            ("Animal International Resource Library Website"), the user ("You")
            accepts these Terms of Use. If You do not accept these Terms of Use,
            do not use or download materials on this website. Anima
            International may modify all or any part of these Terms of Use from
            time to time and may not provide notice to You. You are encouraged
            to check back often so You are aware of your current rights and
            responsibilities. Your continued use of this Website after changes
            to the Terms of Use have been published constitutes your binding
            acceptance of the updated Terms of Use. If at any time the Terms of
            Use are no longer acceptable to You, You should immediately cease
            all use of this Website.
          </Item>
          <Item>
            <strong>Statement of Purpose.</strong> The purpose of the Animal
            International Resource Library Website, is to share free resources
            worldwide with organisations and activists who find value in such
            materials and would like to use them for their personal or
            organisational purposes. This website is designed to share
            information, ideas, relating to animal welfare concepts and
            strategies. It is also designed to encourage the creation of a user
            community through contributions of content and comments. Our hope is
            that the site is accessible and welcoming in order to reach and
            support the animal rights movement.
          </Item>
          <Item>
            <strong>Community Behavior Rules.</strong> To foster an open,
            welcoming, contributory environment you must adhere to the following
            practices:
            <List>
              <Item>
                You may not use This Website’s content to threaten, defamatory,
                libelous, derogatory, violent, harassing, bigoted, hateful,
                profane, obscene, lewd, lascivious, or otherwise objectionable,
                that gives rise to civil or criminal liability, or otherwise
                violates any applicable law
              </Item>
              <Item>
                You may not intentionally make false or misleading statements
                with the content of this Website.
              </Item>
              <Item>
                You may not restrict or inhibit any other user from using or
                enjoying this Website, for example, by cracking, spoofing,
                defacing, or impairing functionality.
              </Item>
            </List>
            Anima International has the right, but not the duty, to pre-screen,
            refuse, edit, move or remove anyone who violates these Terms of Use
            or that is unlawful. Anima International has the right to limit or
            terminate Your registration with, or access to, This Website (which
            may include termination of access by anyone at your IP address) for
            breach of these Terms or Use
          </Item>
          <Item>
            <strong>User Registration.</strong> Registration at Anima
            International is optional but encouraged. If you are registered at
            This Website, Anima International will be able to contact you with
            information that may be relevant to users, the registration will
            facilitate the usage of content, and will allow us to contact you.
            <List>
              <Item>
                You agree not to transfer your use of or access to this Website
                or permit anyone else whose account was suspended or terminated
                to use this Website through your name or password. You are
                responsible for maintaining the confidentiality of your password
                and account and for all activity that occurs on your account.
                Anima International reserves the right to reject or remove any
                user that is in breach of these Terms of Use.
              </Item>
            </List>
          </Item>
          <Item>
            <strong>Anima International’s License to You.</strong> Anima
            International allows You to use, download, share, copy and
            redistribute the materials and resources on This Website in any
            medium or format. You may also adapt, remix, transform, and build
            upon the material for any purpose, even commercially, no attribution
            or credit towards Anima International is needed, only in the case
            where there is an author on the source field, credits are
            appreciated. The materials on This Website are copyright-free,
            including text, images, audio, video, software or other content that
            is made available on This Website. You may use This Website’s
            content for but not limited to, events, campaigns, lobbying, PR as
            well as fundraising purposes. You are responsible for ensuring that
            Your use of content from this Website is lawful.
            <List>
              <Item>
                Anima International licenses the content under these terms with
                the following exceptions:
                <br />
                (1) no license is granted for the use of Anima International’s
                logos; (2) no license is granted for unlawful usage of any of
                the materials available on This Website (3) no license is
                granted for content identified on This Website as not available.
              </Item>
            </List>
          </Item>
          <Item>
            <strong>Your License to Anima International is in use</strong> by
            displaying, publishing or making available any content, text, files,
            images, photos, video, sounds, or any other materials, You represent
            and warrant that the content used does not and will not defame any
            person or entity, or violate or infringe any local, national or
            international laws.
          </Item>
          <Item>
            <strong>
              Accuracy, Completeness and Timeliness of Information on This
              Website.
            </strong>{" "}
            Anima International is not responsible if information made available
            on this Website is not accurate, complete or current. Any reliance
            on the material on this Website is at your own risk. This Website
            may contain certain historical information. Though we will do our
            best to provide updated and high quality content, Anima
            International has no obligation to update any information on this
            Website.{" "}
          </Item>
          <Item>
            <strong>Lawful Use Only.</strong> Your use of this Website is
            subject to all applicable local, state, national and international
            laws and regulations. Any attempt by any person to deliberately
            damage this Website or Anima International, is a violation of
            criminal and civil laws.
          </Item>
          <Item>
            <strong>Indemnification.</strong> Any person or corporation or
            organisation using the content of This Website agrees to defend,
            indemnify and hold Anima International, subsidiaries, affiliates,
            officers, directors, shareholders, predecessors, successors in
            interest, employees, agents and licensors harmless from and against
            any and all claims, losses, liabilities and expenses (including
            attorneys' fees) (i) related to your breach of this Agreement or
            (ii) related to or arising out of such submitted or usage of
            content, including without limitation claims made by third parties
            for tort, breach of contract, false advertising, product liability,
            and patent, copyright or trademark infringement, even if such
            content is reviewed by Anima International prior to publishing on
            This Website.
          </Item>
          <Item>
            <strong>LIMITATION ON LIABILITY.</strong> THIS WEBSITE AND ALL
            CONTENT, MATERIALS, INFORMATION, SOFTWARE, PRODUCTS AND SERVICES ARE
            PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. YOUR USE OF THIS
            WEBSITE IS AT YOUR OWN RISK. ANIMA INTERNATIONAL DISCLAIMS ALL
            WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON INFRINGEMENT. TO THE FULLEST EXTENT PERMITTED BY LAW, ANIMA
            INTERNATIONAL IS NOT LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE,
            SPECIAL, INCIDENTAL,CONSEQUENTIAL, OR EXEMPLARY DAMAGES (INCLUDING,
            WITHOUT LIMITATION, LOSS OF BUSINESS, REVENUE, PROFITS, GOODWILL,
            USE, DATA, ELECTRONICALLY TRANSMITTED ORDERS, OR OTHER ECONOMIC
            ADVANTAGE) ARISING OUT OF OR IN CONNECTION WITH THIS WEBSITE, EVEN
            IF ANIMA INTERNATIONAL HAS PREVIOUSLY BEEN ADVISED OF, OR REASONABLY
            COULD HAVE FORESEEN, THE POSSIBILITY OF SUCH DAMAGES, HOWEVER THEY
            ARISE, WHETHER IN BREACH OF CONTRACT OR IN TORT (INCLUDING
            NEGLIGENCE), INCLUDING WITHOUT LIMITATION DAMAGES DUE TO (a) THE USE
            OF OR THE INABILITY TO USE THE WEBSITE; (b) THE COST OF PROCUREMENT
            OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA,
            INFORMATION OR SERVICES DOWNLOADED OR OBTAINED OR MESSAGES RECEIVED
            OR TRANSACTIONS ENTERED INTO, THROUGH OR FROM THIS WEBSITE; ( c)
            STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE WEBSITE, INCLUDING
            WITHOUT LIMITATION UNAUTHORIZED ACCESS TO OR ALTERATION OF
            TRANSMISSIONS OR DATA, MALICIOUS OR CRIMINAL BEHAVIOR, OR FALSE OR
            FRAUDULENT TRANSACTIONS, OR (d) CONTENT OR INFORMATION YOU MAY
            DOWNLOAD, USE, MODIFY OR DISTRIBUTE. ALTHOUGH WE WILL DO OUR BEST TO
            PROVIDE CONSTANT, UNINTERRUPTED ACCESS TO THIS WEBSITE ANIMA
            INTERNATIONAL MAKES NO WARRANTY THAT, (i) THE SERVICE WILL BE
            UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (ii) THE RESULTS THAT
            MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR
            RELIABLE, (iii) THE QUALITY OF ANY PRODUCTS, SERVICES, CONTENT,
            INFORMATION, OR OTHER MATERIALS OBTAINED BY YOU THROUGH THE SERVICE
            WILL MEET YOUR EXPECTATIONS, (iv) ANY ERRORS IN THE SOFTWARE WILL BE
            CORRECTED, OR THAT THIS WEBSITE, ITS CONTENT, AND THE SERVER ON
            WHICH THE WEBSITE AND CONTENT ARE AVAILABLE ARE FREE OF VIRUSES OR
            OTHER HARMFUL COMPONENTS. ANY MATERIAL (INCLUDING CONTENT)
            DOWNLOADED OR OBTAINED THROUGH THE USE OF THIS WEBSITE IS DONE AT
            YOUR OWN RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO
            YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD
            OF ANY MATERIAL. INFORMATION CREATED BY THIRD PARTIES THAT YOU MAY
            ACCESS ON THIS WEBSITE OR THROUGH LINKS IS NOT APPROVED, ADOPTED OR
            ENDORSED BY ANIMA INTERNATIONAL AND REMAINS THE RESPONSIBILITY OF
            THE THIRD PARTY. TO THE EXTENT ANY JURISDICTION DOES NOT ALLOW THE
            EXCLUSION OR LIMITATION OF DIRECT, INCIDENTAL OR CONSEQUENTIAL
            DAMAGES, PORTIONS OF THE ABOVE LIMITATION OR EXCLUSION MAY NOT
            APPLY.
          </Item>
          <Item>
            <strong>Privacy.</strong> See the Privacy Policy located{" "}
            <Link to="/privacy-policy">here</Link>.
          </Item>
          <Item>
            <strong>Jurisdiction.</strong> These Terms of Use and all claims
            relating to the relationship between the parties are governed under
            the Estonian Code of Civil Procedure. You and Anima International
            submit to the jurisdiction of the Estonian courts.
          </Item>
          <Item>
            <strong>Waiver and Severability.</strong> Anima International’s
            failure to exercise or enforce any right or provision of these Terms
            of Use shall not constitute a waiver of such right or provision. If
            one or more of the provisions contained in this Agreement is held
            invalid, illegal or unenforceable in any respect by any court of
            competent jurisdiction, such holding will not impair the validity,
            legality, or enforceability of the remaining provisions.
          </Item>
          <Item>
            <strong>Entire Agreement.</strong> These Terms of Use supersede all
            previous agreements, understandings, and arrangements between the
            parties, whether oral or written, and constitutes the entire
            agreement between the parties regarding the subject matter herein.
          </Item>
          <Item>
            <strong>Language.</strong> These Terms of Use are in the English
            language only, which language shall be controlling in all respects,
            and all versions of this Agreement in any other language shall be
            for accommodation only and shall not be binding.
          </Item>
          <Item>
            <strong>You agree with Anima International</strong> of the usage in
            good faith of all materials and resources available on This Website.
          </Item>
        </List>
      </Container>
    </>
  )
}

const PageHeader = styled.header`
  padding: 250px 0 100px;
  text-align: center;
`

const Container = styled(BaseContainer)`
  margin-bottom: 205px;
`

const List = styled.ol`
  list-style-type: decimal;

  ol {
    margin-top: 20px;
    margin-left: 40px;
  }
`

const Item = styled.li`
  margin-bottom: 20px;

  strong {
    font-weight: 600;
    color: #000;
  }
`

export default TermsOfUsePage
